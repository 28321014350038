:root {
  --super-success-color: #1890FF;
  --success-color: #34C759;
  --warning-color: #FFCC00;
  --danger-color: #FF3B30;
  --undefined-color: #B3B3B3;

}

#root {
  height: 100%;
}

* {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.ant-select-tree-checkbox-inner {
  border-radius: 2px !important;
}

.action-element {
  cursor: pointer;
}

.action-element:hover {
  color: #1890ff !important;
}
